<template>
  <router-view>
  </router-view>
</template>

<script>

export default {
  name: 'App',
  data: function () {
    return {

    }
  },
  components: {

  }
}
</script>