<template>
  <div id="page" class="p-0">
    <navbar-component />
    <nav class="navbar navbar-light bg-light border-bottom fixed-top fixed-top-second-position">
      <div class="container-fluid">
        <h6 v-if="me && licences"><b>Vos licences</b> ({{licences.length}}/{{me.licencesNumber}})</h6>
        <div class="d-flex" v-if="me && licences && (licences.length < me.licencesNumber)">
          <input type="number" class="form-control form-control-sm new-keys" placeholder="Nb clés" v-model="newLicenceNumber" />
          <a class="nav-link ms-2" @click="addLicences(newLicenceNumber)">
            <img src="@/assets/images/ico-add.svg" alt="Add licences">
          </a>
        </div>
        
      </div>
    </nav>
    <div class="container-fluid after-two-navbar">

      <div v-if="error" class="col col-6 ms-auto me-auto m-3">
        <div class="alert alert-danger" role="alert">
          {{error}}
        </div>
      </div>

      <div v-if="success" class="col col-6 ms-auto me-auto m-3">
        <div class="alert alert-success" role="alert">
          {{success}}
        </div>
      </div>
      
      <div class="row border-bottom head">
        <div class="col">Clé de licence</div>
        <div class="col">Utilisateur</div>
        <div class="col">Actions</div>
      </div>
      <div class="row border-bottom body" v-for="licence in licences" v-bind:key="licence.id">
        <div class="col"><code>{{ licence.key }}</code></div>
        <div class="col" v-if="licence.user"><router-link :to="{ name: 'User', params: { id: licence.user.id, module: 'competences' } }">{{ licence.user.firstname }} {{ licence.user.lastname }} - {{ licence.user.email }}</router-link></div>
        <div class="col" v-if="licence.user"></div>

        <div class="col" v-if="!licence.user">Non assignée</div>
        <div class="col actions d-flex justify-content-end" v-if="!licence.user">
          <input type="email" class="form-control m-2" v-model="licence.email" :placeholder="(licence.invitedEmail) ? licence.invitedEmail : 'Email de l\'invité'" :disabled="licence.invitedEmail" />
          <a class="btn btn-primary m-2" @click="invite(licence.key, licence.email, licence.invitedEmail)">
            <span>Inviter</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/navigation/NavbarComponent.vue';
import licenceService from '@/services/licenceService';
import userService from '@/services/userService';
import { thisExpression } from '@babel/types';

export default {
  name: 'LicenceView',
  components: {
    'navbar-component': NavbarComponent
  },
  data: function () {
    return {
      error: null,
      success: null,
      licences: null,
      me: null
    }
  },
  mounted () {
    this.loadMe();
    
    
  },
  methods: {
    addLicences(nb)
    {
      if(!isNaN(nb) && (nb>0) && (this.licences.length+nb <= this.me.licencesNumber))
      {
        if(confirm("Souhaitez-vous générer "+nb+" clé(s) de licence ?"))
        {
          this.error = null;
          this.success = null;

          licenceService.addKeys(nb).then(
            response => {
              this.error = null;
              console.log(response)
              this.success = response.message;
              this.newLicenceNumber = null;
              this.loadMe();
            },
            errors => {
              this.error = errors.response.data.message;
            }
          );
        }
      }
    },
    invite(k, email, emailPlaceholder)
    {
      this.error = null;
      this.success = null;

      if(!email)
      {
        email = emailPlaceholder;
      }

      if(email && k)
      {
        if(confirm("Souhaitez-vous envoyer une invitation à "+email+" ?"))
        {
          licenceService.invite(k, email).then(
            response => {
              this.error = null;
              this.success = response.message;
              this.loadLicences();
            },
            errors => {
              this.error = errors.response.data.message;
            }
          );
        }
      }
    },
    loadMe() {  
      this.error = null;
      

      userService.getList().then(
        response => {
          this.me = response;
          this.error = null;
          this.loadLicences();
        },
        errors => {
          this.error = errors.response.data.message;
        }
      );
    },
    loadLicences() {  
      this.error = null;
      

      licenceService.getList().then(
        response => {
          this.licences = response;
          this.error = null;
        },
        errors => {
          this.error = errors.response.data.message;
        }
      );
    }
  }

}
</script>



<style scoped lang="scss">

</style>