class eventConfig {
  static UNAUTHORIZED = 'UNAUTHORIZED_EVENT';
  
  static RELOAD_SESSIONS = 'reloadSessions';
  static RELOAD_QAS = 'reloadQas';
  static RELOAD_CHANNELS = 'reloadChannels';
  static RELOAD_STREAMS = 'reloadStreams';
  static RELOAD_QUESTIONS = 'reloadQuestions';

  static SHOW = 'show';
  static NEW = 'new';
  static SELECT = 'select';
  static UPDATE = 'update';
  static DELETE = 'delete';
  static DISCONNECT = 'disconnect';
  static BAN = 'ban';
  static CHANGE = 'change';
  static SOCKET_CHANGE = 'socketChange';
  static STREAM_CHANGE = 'streamChange';
  static CHANNEL_CHANGE = 'channelChange';

  static CREATED = 'created';
  static CANCELED = 'canceled';
  static UPDATED = 'updated';
  static DELETED = 'deleted';
  static LOADED = 'loaded';
  static IMPORTED = 'imported';
  static DISCONNECTED = 'disconnected';
  static BANNED = 'banned';

 }

module.exports = eventConfig;