<template>
  <nav class="nav-main navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/hat.svg" width="40" height="40" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ms-auto">
          
          <li class="nav-item mt-2 mt-sm-0 me-sm-3 home">
            <router-link class="nav-link" :to="{ name: 'Licence' }">
              <span>Licences</span>
            </router-link>
          </li>
          
          
          <signout-component />
          
        </ul>
        
      </div>
    </div>
  </nav>
</template>

<script>
import SignoutComponent from '@/components/navigation/SignoutComponent.vue';


export default {
  name: 'NavbarComponent',
  components: {
    "signout-component": SignoutComponent
  },  
  props: {
  },
  data: function () {
    return {

    }
  },
  methods: {
    
  }
}
</script>