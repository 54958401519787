<template>
  <div id="page" class="p-0">
    <navbar-component />
    <nav class="navbar navbar-light bg-light border-bottom fixed-top fixed-top-second-position">
      <div class="container-fluid" v-if="user">
        
        <h6><b>{{user.firstname}} {{user.lastname}} - {{user.email}}</b></h6>
        <div class="d-flex user-actions">
          <router-link class="nav-link me-3" :class="{'active': $route.params.module=='competences'}" :to="{ name: 'User', params: { id: $route.params.id, module: 'competences' } }">
            Compétences
          </router-link>
          <router-link class="nav-link me-3" :class="{'active': $route.params.module=='quizStart'}" :to="{ name: 'User', params: { id: $route.params.id, module: 'quizStart' } }">
            Quiz de début
          </router-link>
          <router-link class="nav-link" :class="{'active': $route.params.module=='quizEnd'}" :to="{ name: 'User', params: { id: $route.params.id, module: 'quizEnd' } }">
            Quiz de fin
          </router-link>
        </div>
        
      </div>
    </nav>
    <div class="container-fluid after-two-navbar">

      <user-competences-component v-if="user && $route.params.module=='competences'" :startQuizData="user.startQuizData" :endQuizData="user.endQuizData" />
      <user-start-quiz-component v-if="user && $route.params.module=='quizStart'" :startQuizForm="user.startQuizForm" />
      <user-end-quiz-component v-if="user && $route.params.module=='quizEnd'" :endQuizForm="user.endQuizForm" />

    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/navigation/NavbarComponent.vue';
import UserCompetencesComponent from '@/components/user/UserCompetencesComponent.vue';
import UserStartQuizComponent from '@/components/user/UserStartQuizComponent.vue';
import UserEndQuizComponent from '@/components/user/UserEndQuizComponent.vue';
import userService from '@/services/userService';

export default {
  name: 'UserView',
  components: {
    'navbar-component': NavbarComponent,
    'user-competences-component': UserCompetencesComponent,
    'user-start-quiz-component': UserStartQuizComponent,
    'user-end-quiz-component': UserEndQuizComponent
  },
  data: function () {
    return {
      user: null
    }
  },
  mounted () {
    
    this.loadUser(this.$route.params.id);
  },
  methods: {
    loadUser(id)
    {
      userService.getUser(id).then(
        response => {
          this.user = response;
        },
        errors => {
          console.log(errors.response.data.message);
        }
      );
    }
  }

}
</script>



<style scoped lang="scss">

</style>