<template>
  <div>
    <div v-for="(question, qIndex) in endQuiz" class="form-group mt-5" v-bind:key="qIndex">
      <div v-if="question.type == 'unique-choice'">
        <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
        <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
          <input disabled class="form-check-input" type="radio" v-model="endQuiz[qIndex].value" :id="'Eq'+qIndex+'a'+aIndex" :value="aIndex">
          <label class="form-check-label" :for="'Eq'+qIndex+'a'+aIndex" v-html="answer.label"></label>
        </div>
      </div>

      <div v-if="question.type == 'multiple-choice'">
        <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
        <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
          <input disabled class="form-check-input" type="checkbox" v-model="endQuiz[qIndex].value" :id="'Eq'+qIndex+'a'+aIndex" :value="aIndex">
          <label class="form-check-label" :for="'Eq'+qIndex+'a'+aIndex" v-html="answer.label"></label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'UserEndQuizComponent',
  components: {
  },  
  props: {
    endQuizForm: String
  },
  data: function () {
    return {
      endQuiz: null
    }
  },
  mounted () {
    this.endQuiz = JSON.parse(this.endQuizForm);
  },  
  methods: {
    
  }
}
</script>