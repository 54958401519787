import axios from 'axios';
import cookieService from './cookieService';

export default class authService {

    static signOut () {
        cookieService.removeToken();
    }


    static isUserAuth () {
        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+"/user/isAdminAuth", {
                headers: {
                'Authorization': 'Bearer ' + cookieService.getToken(),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
            });
         });
       
    }


    static signin (email, password) {

        let data = {
            email: email,
            password: password
        }; 
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL+"/user/signin", data)
            .then(response => {
                cookieService.setToken(response.data.token);
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
         });
    
        
    }


}
