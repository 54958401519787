import apiService from './apiService';

export default class userService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/user/me")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static getUser (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/user/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



}
