<template>
  <div>
    <div v-for="(question, qIndex) in startQuiz" class="form-group mt-5" v-bind:key="qIndex">
        <div v-if="question.type == 'unique-choice'">
          <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
          <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
            <input disabled class="form-check-input" type="radio" v-model="startQuiz[qIndex].value" :id="'Sq'+qIndex+'a'+aIndex" :value="aIndex">
            <label class="form-check-label" :for="'Sq'+qIndex+'a'+aIndex" v-html="answer.label"></label>
          </div>
        </div>

        <div v-if="question.type == 'multiple-choice'">
          <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
          <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
            <input disabled class="form-check-input" type="checkbox" v-model="startQuiz[qIndex].value" :id="'Sq'+qIndex+'a'+aIndex" :value="aIndex">
            <label class="form-check-label" :for="'Sq'+qIndex+'a'+aIndex" v-html="answer.label">
            </label>
          </div>
        </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'UserStartQuizComponent',
  components: {
  },  
  props: {
    startQuizForm: String
  },
  data: function () {
    return {
      startQuiz: null
    }
  },
  mounted () {
    this.startQuiz = JSON.parse(this.startQuizForm);
  },  
  methods: {
    
  }
}
</script>