import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import "bootstrap"

import App from './App.vue'
import router from './router'

import eventDispatcher from './eventDispatcher'

import './sass/app.scss'
import eventConfig from './configs/eventConfig'

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}


const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
})

const app = createApp(App);
app.use(i18n);
app.use(router);

eventDispatcher.on(eventConfig.STREAM_CHANGE, (data) => {
    
  //console.log(data.stream);
  //console.log(data.document);
  });

app.config.globalProperties.eventDispatcher = eventDispatcher;
app.mount('#app');