import apiService from './apiService';

export default class licenceService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/licence/list")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static addKeys (nb) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/licence/generate", {count: nb})
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



    static invite (k, email) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/licence/invite", {key: k, email: email})
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



}
