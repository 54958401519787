<template>
  <div>
    <div class="row">
      <div class="col col-6">
        <h3>Avant le jeu</h3>
        <div class="head" v-if="startQuizThemeScores">
          <div class="avg-score" :class="(getStartQuizThemeScoresAvg() < 25) ? 'red' : (getStartQuizThemeScoresAvg() < 50) ? 'orange' : (getStartQuizThemeScoresAvg() < 75) ? 'yellow' : 'green'">
            <h4 class="mt-2">Total {{getStartQuizThemeScoresAvg()}}%</h4>
          </div>
        </div>
        <div v-if="startQuizThemeScores" class="scores mt-3">
          <div v-for="(ts, index) in startQuizThemeScores" v-bind:key="index" class="score mt-2" :class="( Math.round(ts.score/ts.maxScore*100) < 25) ? 'red' : (Math.round(ts.score/ts.maxScore*100) < 50) ? 'orange' : (Math.round(ts.score/ts.maxScore*100) < 75) ? 'yellow' : 'green'">
            <div class="label">{{getThemeNameByCode(ts.code)}}</div>
            <div class="data">
              <div class="bar">
                <div class="data-value" :style="'width: '+(Math.round(ts.score/ts.maxScore*100))+'%;'"></div>
              </div>
              <div class="data-text"><strong>{{(Math.round(ts.score/ts.maxScore*100))}}%</strong></div>
            </div>

          </div>
        </div>
      </div>
      <div class="col col-6">
        <h3>Après le jeu</h3>
        <div class="head" v-if="endQuizThemeScores">
          <div class="avg-score" :class="(getEndQuizThemeScoresAvg() < 25) ? 'red' : (getEndQuizThemeScoresAvg() < 50) ? 'orange' : (getEndQuizThemeScoresAvg() < 75) ? 'yellow' : 'green'">
            <h4 class="mt-2">Total {{getEndQuizThemeScoresAvg()}}%</h4>
          </div>
        </div>
        <div v-if="endQuizThemeScores" class="scores mt-3">
          <div v-for="(ts, index) in endQuizThemeScores" v-bind:key="index" class="score mt-2" :class="( Math.round(ts.score/ts.maxScore*100) < 25) ? 'red' : (Math.round(ts.score/ts.maxScore*100) < 50) ? 'orange' : (Math.round(ts.score/ts.maxScore*100) < 75) ? 'yellow' : 'green'">
            <div class="label">{{getThemeNameByCode(ts.code)}}</div>
            <div class="data">
              <div class="bar">
                <div class="data-value" :style="'width: '+(Math.round(ts.score/ts.maxScore*100))+'%;'"></div>
              </div>
              <div class="data-text"><strong>{{(Math.round(ts.score/ts.maxScore*100))}}%</strong></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserCompetencesComponent',
  components: {
  },  
  props: {
    startQuizData: String,
    endQuizData: String
  },
  data: function () {
    return {
      startQuizThemeScores: [],
      endQuizThemeScores: [],
      "themeNames" : [
      {
        "code": "SECURE-PASSWORD",
        "name": "Sécuriser vos mots de passe"
      },
      {
        "code": "MAKE-AWARE-USERS",
        "name": "Sensibiliser les utilisateurs"
      },
      {
        "code": "PROTECT-INFORMATION",
        "name": "Protéger vos informations"
      },
      {
        "code": "SECURE-TRIPS",
        "name": "Sécuriser les déplacements"
      },
      {
        "code": "CONTROL-ACCESS-RIGHTS",
        "name": "Maîtriser les accès et les droits"
      },
      {
        "code": "KNOW-COMPUTER-SERVICE",
        "name": "Connaître son SI"
      },
      {
        "code": "SECURE-MOBILE-COMPUTING",
        "name": "Sécuriser l'informatique mobile"
      },
      {
        "code": "SECURE-WIFI-ACCESS",
        "name": "Sécuriser l'accès WiFi"
      },
      {
        "code": "SECURE-NETWORK",
        "name": "Sécuriser le réseau"
      },
      {
        "code": "SECURE-EMAIL-NAVIGATION",
        "name": "Sécuriser la messagerie et les échanges d'information"
      },
      {
        "code": "KEEP-COMPUTER-SERVICE-UPDATED",
        "name": "Maintenir votre SI à jour"
      },
      {
        "code": "SECURE-DESKTOP",
        "name": "Sécuriser les postes de travail"
      },
      {
        "code": "MANAGE-BACKUPS",
        "name": "Gérer vos sauvegardes"
      },
      {
        "code": "SEPARATE-PRO-PERSO",
        "name": "Séparer les usages PRO et PERSO"
      },
      {
        "code": "MANAGE-INCIDENTS",
        "name": "Gérer les incidents"
      },
      {
        "code": "PROTECT-PERSONAL-DATA",
        "name": "Protéger vos informations sensibles et personnelles"
      }
      ]
    }
  },
  mounted () {
    this.startQuizThemeScores = JSON.parse(this.startQuizData);
    this.endQuizThemeScores = JSON.parse(this.endQuizData);
  },
  methods: {
    
    getStartQuizThemeScoresAvg() {
      var cumulScore = 0;
      var cumulMaxScore = 0;
      for(let t of this.startQuizThemeScores)
      {
        cumulScore += t.score;
        cumulMaxScore += t.maxScore;
      }

      return Math.round(cumulScore/cumulMaxScore*100);
    },
    getEndQuizThemeScoresAvg() {
      var cumulScore = 0;
      var cumulMaxScore = 0;
      for(let t of this.endQuizThemeScores)
      {
        cumulScore += t.score;
        cumulMaxScore += t.maxScore;
      }

      return Math.round(cumulScore/cumulMaxScore*100);
    },
    getThemeNameByCode(code) {
      for(let t of this.themeNames)
      {
        if(t.code == code)
        {
          return t.name;
        }
      }

      return null;
    }

  }
}
</script>